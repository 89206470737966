import { BrowserStorage } from "@repo/storage";
import { useNavigate, useSearchParams } from "@solidjs/router";
import { type ParentComponent, createEffect } from "solid-js";
import { urls } from "@core/lib/urls";
import { useWire } from "@core/wire";

const Auth: ParentComponent = (props) => {
  const wire = useWire();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  createEffect(() => {
    if (wire.services.auth.isReady() && !wire.services.auth.isGuest()) {
      /**
       * When signing in with google, we don't have a way to pass the invite token and get it back
       * from the OAUTH flow like with the magic link. Luckily, if the invite token is present in
       * the URL, the OAUTH flow will redirect back to the same url including the invite token.
       *
       * We then intercept that redirect and navigate to the accept invite page.
       */
      if (typeof params.invite === "string") {
        navigate(urls.acceptInvite(params.invite));
        return;
      }
      if (typeof params.collectionLink === "string") {
        navigate(urls.acceptCollectionShareableLink(params.collectionLink));
        return;
      }

      const referrer = BrowserStorage.getSignupReferrerUrl();
      if (referrer) {
        BrowserStorage.removeSignupReferrerUrl();
        navigate(referrer);
      } else {
        navigate("/");
      }
    }
  });
  return <>{props.children}</>;
};
export default Auth;
